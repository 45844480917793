#writing {
    padding-top: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-items: center;
    width: 100%;
    padding-left: 2rem;
    padding-right:2rem;
}

#writing a {
    background-color: #f3dada;
    color: #000411;
}

#writing-text {
    max-width: 900px;
    font-family: 'Libre Franklin', sans-serif;
}

#header > h1 {
    font-family: 'Roboto Monospace', monospace;
    font-weight: bold;
    font-size: 32px;
}
