.nav-item {
    padding-left: 10px;
    font-size: x-large;
}

.nav-link a:hover {
    text-decoration: none;
}

.navbar {
    background-color: #f3dada !important;
    font-family: 'Libre Franklin', sans-serif !important; 
    font-weight: lighter;
    font-size:xx-large;
    color: black;
}

.navbar a {
    color: black;
}

.navbar a:hover {
    text-decoration: none;
    color: black;
}

.vertical-center {
    padding-top: 7px;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    /**
    font-family: 'Libre Franklin', sans-serif !important;
    font-size: x-large !important;
    color: pink !important;
    position: fixed !important;
    top: 0 !important;

     */
}
