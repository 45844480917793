#dive {
    padding-top: 15vh;
    font-family: "Libre Franklin", sans-serif;
    padding-left: 2vh;
    padding-right: 2vh;
}

#dive > h1 {
    text-align: center;
}
