.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link a:link {
  transition: all 0.8s ease;
  background-color: #f3dada;
  color: white;
}

.App-link a:hover {
  color: white;
  background-color: #e1a3a3;
}

#footer {
  color: #000411;
  font-family: 'Libre Franklin', sans-serif;
  text-align: center;
}

/**
Fonts:
font-family: 'Roboto Mono', monospace;
font-family: 'Libre Franklin', sans-serif;
*/
