#experience {
    padding-top: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-items: center;
    width: 100%;
    padding-left: 2rem;
    padding-right:2rem;
}

#header > h1 {
    font-family: 'Roboto Monospace', monospace;
    font-weight: bold;
    font-size: 32px;
}
#experience-cards {
    padding-top: 3vh;
}

#experience-cards .card {
    width: 300px;
    border-radius: 14px;
    font-family: 'Libre Franklin', sans-serif;
    margin-bottom: 3vh;
}

#experience-cards > ul {
    margin-left: 1vh;
}

@media screen and ( min-width: 700px ) {
    #experience-cards .card {
        width: 500px;
    }
}

@media screen and ( min-width: 1000px ) {
    #experience-cards .card {
        width: 800px;
    }
}